import Navbar from './shared/navbar';
import Scroller from './shared/scroller';
import AOS from 'aos';
import Logo from '../assets/aftabeShafa.svg';
import '../styles/style.scss';

window.addEventListener('load', () => {
  const logo = document.getElementById('logo');
  logo.src = Logo;

  const homeLink = document.querySelector('#return-home');
  if (homeLink)
    homeLink.addEventListener('click', (e) => {
      e.preventDefault();
      const url = window.location.toString().split('/pages')[0];
      window.location = url + '/pages/home';
    });

  AOS.init({
    duration: 1000,
  });
  new Navbar();
  new Scroller();
});
